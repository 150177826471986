/**
 * RBKmoney Questionary aggr proxy API
 *  Proxy service for DaData/KonturFocus API 
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@rbk.money
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type FounderType = 'Legal' | 'Physical';

export const FounderType = {
    Legal: 'Legal' as FounderType,
    Physical: 'Physical' as FounderType
};
